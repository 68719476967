import React, { useState, useEffect } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Button,
    Grid,
    TextField,
    CircularProgress,
    LinearProgress,
    Divider,
    Box,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import LoginIcon from '@mui/icons-material/Login';
import InfoIcon from '@mui/icons-material/Info';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';
import './styles.css';
import { QrReader } from 'react-qr-reader';
import { Pie } from 'react-chartjs-2'; // Import the Pie chart component
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'; // Import Chart.js components

// Register Chart.js components
ChartJS.register(ArcElement, Tooltip, Legend);

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#41b6e6',
        },
        background: {
            default: '#000000',
            paper: '#000000',
        },
        appBar: {
            main: '#000000', // Couleur de l'AppBar
        }
    },
});

function App() {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [activePage, setActivePage] = useState("home");
    const [products, setProducts] = useState([]);
    const [orders, setOrders] = useState([]);
    const [cart, setCart] = useState([]);
    const [quantities, setQuantities] = useState({});
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userName, setUserName] = useState('Demo');
    const [scannedProduct, setScannedProduct] = useState(null);
    const [scannedCodeContent, setScannedCodeContent] = useState(null);
    const [scannedCodeType, setScannedCodeType] = useState(null);

    const toggleDrawer = (open) => () => {
        setDrawerOpen(open);
    };

    // Function to fetch products from WooCommerce
    const fetchProducts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.apiUrl}/wp-json/wc/v3/products`, {
                params: {
                    consumer_key: config.consumerKey,
                    consumer_secret: config.consumerSecret,
                },
            });
            setProducts(response.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setLoading(false);
        }
    };

    // Function to fetch orders for the client
    const fetchOrders = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${config.apiUrl}/wp-json/wc/v3/orders`, {
                params: {
                    consumer_key: config.consumerKey,
                    consumer_secret: config.consumerSecret,
                    email: 'demo@pharmsultation.com',
                },
            });
            setOrders(response.data);
        } catch (error) {
            console.error("Error fetching orders:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch products or orders when the page changes
    useEffect(() => {
        if (activePage === "products") {
            fetchProducts();
        } else if (activePage === "orders") {
            fetchOrders();
        }
    }, [activePage]);

    // Utility function to format price and append "Euro"
    const formatPrice = (price) => {
        const numericPrice = parseFloat(price.replace(/[^\d.-]/g, ''));
        return `${numericPrice} Euro`;
    };

    const handleConnect = () => {
        // Simulate authentication logic
        if (email === 'demo@pharmsultation.com' && password === 'test') {
            toast.success('Connected successfully!', {
                position: 'bottom-right',
                autoClose: 3000,
            });
            setIsAuthenticated(true);
            setUserName(email);
            setActivePage('orders'); // Redirect to Order List page after login
        } else {
            toast.error('Invalid email or password.', {
                position: 'bottom-right',
                autoClose: 3000,
            });
        }
    };

    const ConnectPage = () => (
        <div style={{ padding: 16 }}>
            <Typography variant="h4">Connect</Typography>
            <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                margin="normal"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
                label="Password"
                type="password"
                variant="outlined"
                fullWidth
                margin="normal"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                variant="contained"
                color="primary"
                startIcon={<LoginIcon />}
                onClick={handleConnect}
                fullWidth
                style={{ marginTop: 16 }}
            >
                Connect
            </Button>
        </div>
    );


    // Function to generate chart data
    const getChartData = () => {
        const statuses = { pending: 0, processing: 0, completed: 0 };

        // Count the number of orders for each status
        orders.forEach((order) => {
            if (statuses[order.status] !== undefined) {
                statuses[order.status] += 1;
            }
        });

        return {
            labels: ['Pending', 'Processing', 'Completed'],
            datasets: [
                {
                    data: [statuses.pending, statuses.processing, statuses.completed],
                    backgroundColor: ['#FF6384', '#36A2EB', '#4BC0C0'],
                    hoverBackgroundColor: ['#FF6384', '#36A2EB', '#4BC0C0'],
                },
            ],
        };
    };

    const OrdersPage = () => (
        <div style={{ padding: 16 }}>
            <Typography variant="h4">Order Status</Typography>
            {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                    <CircularProgress />
                </div>
            ) : (
                <Grid container spacing={3}>
                    {orders.map((order) => (
                        <Grid item xs={12} key={order.id}>
                            <div className="order-card">
                                <Typography variant="h6">Order #{order.id}</Typography>
                                <Typography variant="body2">{formatPrice(order.total)}</Typography>
                                <LinearProgress
                                    variant="determinate"
                                    value={order.status === 'completed' ? 100 : 50} // Example: 50% for "processing", 100% for "completed"
                                    style={{ marginTop: 10 }}
                                />
                                <Typography variant="body2" style={{ marginTop: 10 }}>
                                    Status: {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
                                </Typography>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            )}
        </div>
    );

    const HomePage = () => (
        <div style={{ padding: 16 }}>
            <Typography variant="body1" style={{ marginTop: 20 }}>
                <center>
                <img alt="" style={{ width: '100%', height: '100%' }} src="https://www.pharmsultation.com/wp-content/uploads/2023/05/customcolor_logo_transparent_background-1024x614-1-cropped-300x114.png" alt="PharmSultation Logo" />
                <br/>
                <img alt="" style={{ width: '100%', height: '100%' }} src="https://www.pharmsultation.com/wp-content/uploads/2023/05/pharmasultation_world_background_black.png" alt="PharmSultation Logo" />
                <br/>
                </center>
            </Typography>
        </div>
    );

    const ProductsPage = () => {
        const handleQuantityChange = (productId, value) => {
            setQuantities({
                ...quantities,
                [productId]: value,
            });
        };

        return (
            <div style={{ padding: 16 }}>
                <Typography variant="h4">Product List</Typography>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
                        <CircularProgress />
                    </div>
                ) : (
                    <Grid container spacing={3}>
                        {products.map((product) => (
                            <Grid item xs={12} sm={6} md={4} key={product.id}>
                                <div className="product-card">
                                    <img src={product.images[0]?.src} alt={product.name} className="product-image" />
                                    <Typography variant="h6" style={{ marginTop: '8px' }}>{product.name}</Typography>
                                    <Typography variant="body2">{formatPrice(product.price)}</Typography>

                                    <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 10 }}>
                                        <Grid item>
                                            <TextField
                                                label="Quantity"
                                                type="number"
                                                InputProps={{ inputProps: { min: 1 } }}
                                                variant="outlined"
                                                size="small"
                                                defaultValue={1}
                                                onChange={(e) => handleQuantityChange(product.id, parseInt(e.target.value))}
                                                className="quantity-input"
                                                style={{ width: '100px' }}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                startIcon={<AddShoppingCartIcon />}
                                                onClick={() => addToCart(product, quantities[product.id] || 1)}
                                                style={{ marginLeft: 10 }}
                                            >
                                                Add to Cart
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                )}
            </div>
        );
    };

    const addToCart = (product, quantity) => {
        const newCartItem = { ...product, quantity };
        setCart([...cart, newCartItem]);
        toast.success(`${product.name} (Quantity: ${quantity}) added to cart!`, {
            position: 'bottom-right',
            autoClose: 3000,
        });
    };

    const CartPage = () => {
        const total = cart.reduce((sum, product) => sum + product.price * product.quantity, 0);

        return (
            <div style={{ padding: 16 }}>
                <Typography variant="h4">My Cart</Typography>
                {cart.length === 0 ? (
                    <Typography variant="body1">Your cart is empty.</Typography>
                ) : (
                    <>
                        {cart.map((product, index) => (
                            <div key={index} className="product-card">
                                <Typography variant="h6">{product.name} (Quantity: {product.quantity})</Typography>
                                <Typography variant="body2">{formatPrice(product.price)}</Typography>
                                <Button
                                    variant="outlined"
                                    className="remove-button"
                                    startIcon={<DeleteIcon />}
                                    onClick={() => removeFromCart(index)}
                                >
                                    Remove
                                </Button>
                            </div>
                        ))}
                        <Typography variant="h6" className="total">Total: {total.toFixed(2)} Euro</Typography>
                    </>
                )}
            </div>
        );
    };

    const removeFromCart = (index) => {
        const product = cart[index];
        const newCart = cart.filter((_, i) => i !== index);
        setCart(newCart);
        toast.info(`${product.name} removed from cart.`, {
            position: 'bottom-right',
            autoClose: 3000,
        });
    };

    const ProjectManagementPage = () => {
        const tasks = [
            { name: "Task 1", status: "New", progress: 20 },
            { name: "Task 2", status: "In Progress", progress: 60 },
            { name: "Task 3", status: "Completed", progress: 100 },
        ];

    return (
        <div style={{ padding: 16 }}>
            <Typography variant="h4">Project: Awesome Project</Typography>
            <div style={{ marginTop: 20 }}>
                {tasks.map((task, index) => (
                    <div key={index} style={{ marginBottom: 20 }}>
                        <Typography variant="h6">{task.name} - Status: {task.status}</Typography>
                        <LinearProgress variant="determinate" value={task.progress} style={{ marginTop: 10 }} />
                    </div>
                ))}
            </div>
        </div>
    );
    };

    const ProductInfoPage = () => {
        const handleScan = (result, error) => {
            if (!!result) {
                setScannedCodeContent(result.text);
                setScannedCodeType(result.decodedText ? result.decodedText.type : 'Unknown Type');

                const randomProduct = products[Math.floor(Math.random() * products.length)];
                setScannedProduct(randomProduct ? randomProduct.name : 'Unknown Product');
            }

            if (!!error) {
                console.error('Error scanning QR code:', error);
            }
    };
        return (
            <div style={{ padding: 16 }}>
                <Typography variant="h4">Product Info</Typography>
                <QrReader
                    onResult={handleScan}
                    constraints={{ facingMode: 'environment' }}
                    style={{ width: '100%' }}
                />
                {scannedProduct && (
                    <div>
                        <Typography variant="h6" style={{ marginTop: 20 }}>
                            Scanned Product: {scannedProduct}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 10 }}>
                            Barcode Content: {scannedCodeContent}
                        </Typography>
                        <Typography variant="body1" style={{ marginTop: 10 }}>
                            Barcode Type: {scannedCodeType}
                        </Typography>
                    </div>
                )}
            </div>
        );
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <div style={{ minHeight: '100vh' }}>
                <AppBar position="static" style={{ backgroundColor: darkTheme.palette.background.default }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                            <MenuIcon />
                        </IconButton>
                        {/* Add the PNG icon between the menu and title */}
                        <Box sx={{ display: 'flex', alignItems: 'center', ml: 1, mr: 1 }}>
                            <img src="/icons/icon-192x192.png" alt="Logo" style={{ width: '30px', height: '30px' }} />
                        </Box>

                        <Typography variant="h6">PharmSultation Mobile</Typography>
                    </Toolbar>
                </AppBar>

                <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)} style={{ backgroundColor: darkTheme.palette.background.paper }}>
                    <div style={{ width: 250, height: '100%' }}>
                        <div style={{ padding: 16 }}>
                            <Typography variant="body1">
                                {isAuthenticated ? `${userName}` : 'demo@pharmsultation.com'}
                            </Typography>
                        </div>
                        <List>
                            <ListItem button onClick={() => { setActivePage('home'); setDrawerOpen(false); }}>
                                <HomeIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Home" />
                            </ListItem>
                            <ListItem button onClick={() => { setActivePage('connect'); setDrawerOpen(false); }}>
                                <LoginIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Connect" />
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => { setActivePage('orders'); setDrawerOpen(false); }}>
                                <ListAltIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Order Status" />
                            </ListItem>
                            <ListItem button onClick={() => { setActivePage('products'); setDrawerOpen(false); }}>
                                <ListAltIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Product List" />
                            </ListItem>
                            <ListItem button onClick={() => { setActivePage('cart'); setDrawerOpen(false); }}>
                                <ShoppingCartIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="My Cart" />
                            </ListItem>
                            <Divider />
                            <ListItem button onClick={() => { setActivePage('project-management'); setDrawerOpen(false); }}>
                                <ListAltIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Project Management" />
                            </ListItem>
                            <ListItem button onClick={() => { setActivePage('product-info'); setDrawerOpen(false); }}>
                                <QrCodeScannerIcon style={{ marginRight: 8 }} />
                                <ListItemText primary="Product Info" />
                            </ListItem>
                            <Divider />
                            <a href="https://www.pharmsultation.com/contact-us" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                                <ListItem button>
                                    <ContactMailIcon style={{ marginRight: 8 }} />
                                    <ListItemText primary="Contact Us" />
                                </ListItem>
                            </a>
                        </List>
                    </div>
                </Drawer>

                {activePage === 'home' && <HomePage />}
                {activePage === 'connect' && <ConnectPage />}
                {activePage === 'orders' && <OrdersPage />}
                {activePage === 'products' && <ProductsPage />}
                {activePage === 'cart' && <CartPage />}
                {activePage === 'product-info' && <ProductInfoPage />}
                {activePage === 'project-management' && <ProjectManagementPage />}

                {/* Render other components based on activePage */}

                <ToastContainer />
            </div>
        </ThemeProvider>
    );
}

export default App;
